<template>
  <div class="analytics-navigation">
    <div class="analytics-navigation__main">
      <nuxt-link class="analytics-navigation__company" to="/">
        <AppLogo class="analytics-navigation__company__logo" />
      </nuxt-link>

      <NavigationSidebarSection :items="mainSectionItems" class="analytics-navigation__main-links" />

      <SpacerBase></SpacerBase>

      <NavigationSidebarSection :items="footerSectionItems" class="analytics-navigation__footer-links" />
    </div>
  </div>
</template>

<script lang="ts">
import NavigationSidebarSection from '~/shared/ui/app/app-navigation-sidebar-section.vue';
import AppLogo from '~/shared/ui/app/app-logo.vue';
import SpacerBase from '~/shared/ui/spacer-base.vue';
import { NavItem } from '~/shared/ui/app/app-navigation.inventory';

export default {
  name: 'AppNavigationSidebar',

  components: {
    AppLogo,
    NavigationSidebarSection,
    SpacerBase,
  },

  computed: {
    mainSectionItems() {
      return this.defineLinks([
        // {
        //   label: 'Segments',
        //   icon: 'eva:bar-chart-outline',
        //   iconClass: 'rotate-90',
        //   to: '/segments',
        // },
        {
          label: 'People',
          icon: 'eva:people-outline',
          to: '/people',
        },
        {
          label: 'Revenue',
          icon: 'eva:bar-chart-2-outline',
          children: this.revenueItems,
        },
        {
          label: 'Analysis',
          icon: 'eva:maximize-outline',
          children: this.productivityItems,
        },
        {
          label: 'Attrition',
          icon: 'eva:people-outline',
          children: this.attritionItems,
        },
        {
          label: 'Integrations',
          icon: 'eva:grid-outline',
          to: '/integrations',
        },
      ]);
    },

    footerSectionItems() {
      return this.defineLinks([
        {
          label: 'Contact support',
          icon: 'eva:email-outline',
          link: 'mailto:support@performerty.ai',
        },
        {
          label: 'Logout',
          icon: 'eva:log-out-outline',
          to: '/logout',
        },
      ]);
    },

    revenueItems() {
      return this.defineLinks([
        // {
        //   label: 'Overview',
        //   icon: 'eva:grid-outline',
        //   to: '/revenue-overview',
        // },
        {
          label: 'Revenue drivers',
          icon: 'eva:swap-outline',
          to: '/revenue-drivers',
        },
        {
          label: 'Cost drivers',
          icon: 'eva:swap-outline',
          to: '/cost-drivers',
        },
        {
          label: 'Risks',
          icon: 'eva:bulb-outline',
          to: '/revenue-risks',
        },
      ]);
    },

    attritionItems() {
      return this.defineLinks([
        {
          label: 'Alerts',
          icon: 'eva:bulb-outline',
          to: '/attrition',
        },
        {
          label: 'Heatmap',
          icon: 'IconHeatmap',
          to: '/attrition/heatmap',
        },
        {
          label: 'Drivers',
          icon: 'eva:swap-outline',
          to: '/attrition/drivers',
        },
        {
          label: 'Results',
          icon: 'eva:checkmark-circle-outline',
          to: '/attrition/results',
        },
      ]);
    },

    productivityItems() {
      return this.defineLinks([
        {
          label: 'Heatmap',
          icon: 'IconHeatmap',
          to: '/productivity/heatmap',
        },
        {
          label: 'Trends',
          icon: 'eva:activity-outline',
          to: '/productivity/trends',
        },
        {
          label: 'Profiles',
          icon: 'IconUserProfile',
          to: '/profile/employee',
        },
      ]);
    },

    routes() {
      const router = useRouter();
      return router.getRoutes();
    },
  },

  methods: {
    defineLinks(items: NavItem[]) {
      return items.filter(this.filterLink);
    },

    filterLink(item: NavItem) {
      if (item.children) {
        return item.children.length > 0;
      }

      if (item.link) {
        return true;
      }

      const route = this.routes.find(route => route.path === item.to);
      if (!route) {
        console.error('Undefined route in app navigation');
        return;
      }

      const permissions = route.meta.permissions;
      return !permissions || userCan(permissions);
    },
  },
};
</script>

<style lang="scss">
@import '../../../app/style/vars/navigation';

.analytics-navigation {
  position: fixed;
  top: 0;
  left: 0;
  transition: $collapseNavigationTransition;
  bottom: 0;
  background: #fff;
  //padding: 0 15px;
  border-right: 1px solid theme('colors.grey.8');
  z-index: theme('zIndex.40');
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  height: 100%;

  // &::-webkit-scrollbar {
  //   display: none; /* for Chrome, Safari, and Opera */
  // }

  scrollbar-width: thin; /* for Firefox */

  &__main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;

    &__item {
    }
  }

  &__company {
    flex-grow: 0;
    flex-shrink: 0;
    height: 20px;
    padding: 0 15px 0 27px;
    margin-top: 28px;
    margin-bottom: 20px;
    display: flex;
    /*align-items: center;*/
    /*justify-content: center;*/
    background: rgba(255, 255, 255, 0.01);
    /*border-bottom: 1px solid rgba(0, 0, 0, 0.1);*/

    &--hidden {
      opacity: 0;
      display: none;
      /*height: 8px;*/
    }

    &__logo {
      opacity: 1;
      position: relative;
      height: 20px;
      //min-height: 28px;
      flex-shrink: 0;

      path {
        transition: $collapseNavigationTransition;
      }
    }
  }

  &__main-links {
    margin-top: 12px;
  }

  &__main-links,
  &__footer-links {
  }
}
</style>
